<template>
  <v-container>
    <v-row>
      <v-col style="align-self: center">
        <span style="font-size: 14px; font-weight: 700; color: #757575;">
          Visualizado por
          <v-menu
            open-on-hover
            offset-y
            transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                style="color: #1cb4ff">
                {{ selectedSeenByType.text}}
              </span>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in seenByTypeList" :key="index" link>
                <v-list-item-title @click="onSelectItem(item)">{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
      </v-col>

      <v-col>
        <v-radio-group v-model="selectedTimeType" row>
          <v-radio :label="timeTypeList[0].name" :value="timeTypeList[0].value"/>
          <v-radio :label="timeTypeList[1].name" :value="timeTypeList[1].value"/>
          <v-radio :label="timeTypeList[2].name" :value="timeTypeList[2].value"/>
        </v-radio-group>
      </v-col>

      <v-col>
        <v-checkbox v-model="isPendingOperations" label="Considerar operações pendentes" style="" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Events from '@/core/service/events'

export default {
  name: 'MyFilter',

  props: {},
  directives: {},
  components: {},

  data: () => ({
    selectedSeenByType: { text: 'Pagamento', id: 'dt_paid' },
    selectedTimeType: 'month',
    isPendingOperations: true,
    seenByTypeList: [
      { text: 'Pagamento', id: 'dt_paid' },
      { text: 'Emissão', id: 'dt_emission' },
      { text: 'Vencimento', id: 'dt_due' },
    ],
    timeTypeList: [
      { name: 'diário', value: 'day' },
      { name: 'semanal', value: 'week' },
      { name: 'mensal', value: 'month' }
    ]
  }),

  created () {},
  mounted () {},
  computed: {},

  watch: {
    'selectedSeenByType' () {
      const self = this
      self.enviarDados()
    },

    'selectedTimeType' () {
      const self = this
      self.enviarDados()
    },

    'isPendingOperations' () {
      const self = this
      self.enviarDados()
    }
  },

  methods: {
    onSelectItem (item) {
      const self = this
      self.selectedSeenByType = item
    },

    enviarDados () {
      const self = this

      Events.$emit('filter::sendData', {
        selectedSeenByType: self.selectedSeenByType,
        selectedTimeType: self.selectedTimeType,
        isPendingOperations: self.isPendingOperations
      })
    }
  },

  validations () { return {} }
}
</script>